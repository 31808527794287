<template>
  <div class="height_100" v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/personalcenter' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ path: '/product' }"
        >我的产品</el-breadcrumb-item
      >
      <el-breadcrumb-item v-if="correct">修改产品</el-breadcrumb-item>
      <el-breadcrumb-item v-else>添加产品</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dis align_center">
      <div class="icon mart_10"></div>
      <div class="service mart_10" v-if="correct">修改产品</div>
      <div class="service" v-else>添加产品</div>
    </div>

    <el-form
      :model="ruleForm"
      ref="ruleForm"
      :rules="rules"
      label-width="170px"
      style="padding-top: 20px"
    >
      <el-form-item label="产品名称 " prop="title">
        <el-input
          v-model="ruleForm.title"
          placeholder="请输入产品名称"
          class="width_500"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务对象 " prop="object" required>
        <el-input
          v-model="ruleForm.object"
          placeholder="请输入服务对象"
          class="width_500"
        ></el-input>
      </el-form-item>

      <el-form-item label="服务类型 " prop="pro_cate_id" required>
        <el-select
          v-model="ruleForm.pro_cate_id"
          placeholder="请选择服务类型"
          class="width_500"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="城市 " prop="city_id" required>
        <el-select
          v-model="ruleForm.city_id"
          placeholder="请选择城市"
          class="width_500"
        >
          <el-option
            v-for="item in city"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="服务模式 " prop="mode" required>
        <el-radio-group @change="changeradio(ruleForm.mode)" v-model="ruleForm.mode">
          <el-radio v-model="ruleForm.mode" label="1">公益性服务</el-radio>
          <el-radio v-model="ruleForm.mode" label="2"
            >市场化服务

            <el-input :disabled="ruleForm.mode != '2'"
              v-model="ruleForm.serviceradio1"
              class="service_inp"
              placeholder="请输入费用（元）"
            ></el-input>
          </el-radio>
          <el-radio v-model="ruleForm.mode" label="3"
            >公益性市场化服务
            <el-input
            :disabled="ruleForm.mode != '3'"
              v-model="ruleForm.serviceradio2"
              class="service_inp"
              placeholder="请输入费用（元）"
            ></el-input>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="联系人 " prop="contact" required>
        <el-input
          v-model="ruleForm.contact"
          placeholder="请输入联系人"
          class="width_500"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系电话 " prop="mobile" required>
        <el-input
          v-model="ruleForm.mobile"
          placeholder="请输入联系电话"
          class="width_500"
        ></el-input>
      </el-form-item>
      <el-form-item prop="email" label="邮箱" required>
        <el-input
          v-model="ruleForm.email"
          placeholder="请输入邮箱"
          class="width_500"
        ></el-input>
      </el-form-item>
     

      <el-form-item label="产品图片 " required>
        <el-upload
          ref="uploadfiles2"
          :file-list="uploadList1"
          list-type="picture-card"
          class="avatar-uploader"
          action="#"
          accept=".jpg,.jpeg,.png"
          :multiple="true"
          :limit="1"
          name="file2"
          :auto-upload="true"
          :http-request="httpreq1"
          :before-upload="changeUpload2"
          :on-exceed="handleExceed"
          :on-remove="handleRemove2"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <div class="width_50">
        <div class="flex">
          <div class="business left">服务内容</div>
        </div>
        <div class="mat_30 left"></div>
      </div>

      <div style="border: 1px solid #ccc">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; overflow-y: hidden"
          v-model="content"
          :defaultConfig="editorConfig"
          :mode="mode"
          id="#editor"
          @onCreated="onCreated"
        />
      </div>

      <!-- <quillEditor
        v-model="content"
        ref="myQuillEditor"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @change="onEditorChange($event)"
      ></quillEditor> -->

      <el-button
        @click="upproduct()"
        style="background-color: #ff7129; color: #fefefe; margin-top: 30px"
        v-if="correct"
        >确定修改</el-button
      >
      <el-button v-else
        @click="addproduct()"
        style="background-color: #ff7129; color: #fefefe; margin-top: 30px"
        >确认添加</el-button
      >

      <el-button
        style="background-color: #cfcfcf; color: #fefefe; margin-top: 30px"
        @click="balck()"
        >取消</el-button
      >
    </el-form>
  </div>
</template>


<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { quillEditor } from "vue-quill-editor";
// import "quill/dist/quill.core.css"; // 这个CSS必须引入
// import "quill/dist/quill.snow.css"; // 主题CSS

export default {
  data() {
    return {
      // radio: "",
      correct:"",
      loads:[],
      content: "",
      img: "",
      loading:false,
      imglogin:false,//是否有重新上传
      uploadList1:[],
      ruleForm: {
        title: "",
        object: "",
        contact: "",
        mobile: "",
        email: "",
        serviceradio1: "",
        serviceradio2: "",
        mode:""
      },
      options: [],
      rules: {

        title: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
          { required: true, message: "产品名称不能为空", trigger: "change" },
        ],
        object: [
          { required: true, message: "请输入服务对象", trigger: "blur" },
          { required: true, message: "服务对象不能为空", trigger: "change" },
        ],
        pro_cate_id: [{ required: true, message: "请选择服务类型" }],
        city_id: [{ required: true, message: "请选择城市" }],

        mode: [{ required: true, message: "请选择服务模式",trigger: "blur" }],
        contact: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { required: true, message: "联系人不能为空", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { required: true, message: "邮箱不能为空", trigger: "change" },
        ],
        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
      },
      editor: null,
      content: "",
      catelist: [],
      city: [],
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // server: "http://jxx.zhenchangkj.com/v1/upload_editor",
            server: "https://www.smeqh.cn/v1/upload_editor",
            
            allowedFileTypes: ["image/*"],
            maxNumberOfFiles: 1,
          },
        },
      },

      mode: "default", // or 'simple'
    };
  },

  methods: {
    getcity() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("area_select", "data").then((res) => {
        console.log(res, "re789s");
        this.city = res.result;
      });
    },
    changeradio(item) {
      console.log(item, "7892456");
      this.ruleForm.mode = item
      if (item == 2) {
        this.ruleForm.serviceradio2 = "";
        this.ruleForm.serviceradio1 = "";
      } else if (item == 3) {
        this.ruleForm.serviceradio2 = "";
        this.ruleForm.serviceradio1 = "";
      }
    },
    httpreq1(load) {
      this.loads = load.file
      this.imglogin = true
      console.log(this.loads, 'loads')
     
    },
    upproduct() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true
          console.log(valid,'valid')

          if(this.imglogin ){
            let formdata = new FormData();
      formdata.append("file", this.loads);
      this.$post("upload_img", formdata).then((res) => {
        this.img = res.address;
        this.product_up()
        console.log(res, "res");
      });
          }else{
            this.product_up()
          }

         
        }
      });
    },


    addproduct() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true
          console.log(valid,'valid')
           let formdata = new FormData();
      formdata.append("file", this.loads);
      this.$post("upload_img", formdata).then((res) => {
        this.img = res.address;
        this.product_add()
        console.log(res, "res");
      });
        }
      });
    

     
    },
    product_up(){
      let data = {
        id:this.correct,
        token: localStorage.eleToken,
        title: this.ruleForm.title,
        object: this.ruleForm.object,
        pro_cate_id: this.ruleForm.pro_cate_id,
        mode: this.ruleForm.mode,
        contact: this.ruleForm.contact,
        mobile: this.ruleForm.mobile,
        email: this.ruleForm.email,
        img: this.img,
        mode_price: this.ruleForm.serviceradio1 || this.ruleForm.serviceradio2,
        content: this.content,
        city_id: this.ruleForm.city_id,
      };
      console.log(data,'data')
      this.$post("service_product_upd ", data).then((res) => {
        console.log(res, "res");
        this.loading = false
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/product");
      });
    },



    product_add(){
      let data = {
        token: localStorage.eleToken,
        title: this.ruleForm.title,
        object: this.ruleForm.object,
        pro_cate_id: this.ruleForm.pro_cate_id,
        mode: this.ruleForm.mode,
        contact: this.ruleForm.contact,
        mobile: this.ruleForm.mobile,
        email: this.ruleForm.email,
        img: this.img,
        mode_price: this.ruleForm.serviceradio1 || this.ruleForm.serviceradio2,
        content: this.content,
        city_id: this.ruleForm.city_id,
      };
      this.$post("service_product_add ", data).then((res) => {
        console.log(res, "res");
        this.loading = false
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/product");
      });
    },
   


    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        console.log(this.options, "this.options");
      });
    },
    handleExceed() {
      this.$message({
        message: "最大上传数量为1张!",
        type: "warning",
        duration: 1500,
      });
    },

    handleRemove2() {
      this.documentList2 = [];
      this.fileObj2 = {};

      this.percent2 = 0;

      this.imgFlag2 = false;
    },

    changeUpload2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.uploadList1 = []

        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);

      // this.$post("upload_file", formData).then((res) => {
      //   this.finance_img = res.data.address;
      //   this.dialogVisible = false;

      //   let picObj = { url: this.finance_img };
      //   this.uploadList2.push(picObj);
      // });
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    balck() {
      this.$router.push("/product");
      console.log(this.base);
    },
    amendlist(){
      

      let data = { token: localStorage.eleToken ,
        id:this.correct
      };
      this.$get("service_product_lst_d", data).then((res) => {
        this.ruleForm = res.result
        this.ruleForm.mode = res.result.mode == '1' ? '1' : res.result.mode == '2' ?  '2' : '3'
        if(this.ruleForm.mode == 2){
          this.ruleForm.serviceradio1 = res.result.mode_price
        }else if(this.ruleForm.mode == 3){
          this.ruleForm.serviceradio2 = res.result.mode_price
        }
        this.content = res.result.content
        this.img = res.result.img
        let arr = []
        arr.push(res.result.img)
        this.uploadList1 = arr.map((item)=>{
          return{
            url:item,
            name:item
          }
        })
        console.log( this.uploadList1,' this.uploadList1')
      });
    }
  },
  created() {
    this.getcatelist();
    this.getcity();
    // let div = document.getElementsByClassName(Editor);

    console.log(this.baseUrl + "/upload_editor");
  },

  mounted() {
    // 上传图片配置
    // console.log(this.editor, "this.editor");
    // this.editor.customConfig.uploadImgServer = this.baseUrl + "/upload_editor";
    this.correct = this.$route.query.cid;
    if(this.correct){
      this.amendlist()
    }
    console.log(this.$route.query)
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  components: {
    Editor,
    Toolbar,
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.width_500 {
  width: 500px !important;
}
::v-deep .el-form-item__label {
  text-align: left;
}
.service_inp {
  width: 200px;
  height: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #ff6618;
  color: #ff6618;
  border-color: #ff6618;
}
</style>
